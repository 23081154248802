import React from "react";

import Layout from "../components/layout";
import FullPuzzle from "../components/full_puzzle";

const PuzzleIndexPage = ({ pageContext }) => (
  <Layout>
    {pageContext.puzzles.map(puzzle => (
        <React.Fragment>
  	  <FullPuzzle puzzle = {puzzle} />
          <hr/>
        </React.Fragment>
    ))}                     
  </Layout>
);
export default PuzzleIndexPage;
